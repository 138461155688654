<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="initData()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="出运发票号:" prop="tran_no">
              <el-input size="small" v-model.trim="searchForm.tran_no" clearable placeholder="请输入出运发票号"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="销售合同号:" prop="scon_no">
              <el-input size="small" v-model.trim="searchForm.scon_no" clearable placeholder="请输入销售合同号"> </el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getProdsNow()" class="vg_ml_16">查询 </el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" class="vd_dis_right"> </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
      <el-row class="vd_mar10">
        <el-col :md="16">
          <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
          <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :md="24">
        <el-table
          class="vg_cursor"
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loadFlag"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="序号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="销售合同号" prop="scon_no"></el-table-column>
          <el-table-column label="出运发票号" prop="tran_no">
            <template slot-scope="scope">
              <span v-if="scope.row.tran_no">
                {{ helper.transverseTranNo(scope.row.tran_no) }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="客户订单号" prop="scon_cust_no"></el-table-column>
          <el-table-column label="应收款金额" prop="prod_subtotal"></el-table-column>
          <el-table-column label="已收金额" prop="segi_claied"></el-table-column>
          <!-- <el-table-column label="币种" prop="scon_cust_nos"></el-table-column>
          <el-table-column label="汇率" prop="scon_cust_nos"></el-table-column> -->
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { tranAPI } from '@/api/modules/tran';
import { suppAPI } from '@api/modules/supp';
import pubPagination from '@/components/common/pubPagination';
import { useSuppMixin, useCustMixin, useStffMixin, useDeptMixin } from '@/assets/js/mixin/initMixin';
export default {
  name: 'CollecList',
  components: {
    pubPagination
  },
  mixins: [useSuppMixin, useCustMixin, useStffMixin, useDeptMixin],
  data() {
    return {
      tableData: [],
      searchForm: {
        tran_no: null,
        scon_no: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      prodTypes: [],
      contry: [],
      suppList: [],
      custId: null
    };
  },
  created() {},
  filters: {
    // 是否主厂
    formatMainShip(row) {
      if (row === 1) {
        return '是';
      } else if (row === 0) {
        return '否';
      }
    }
  },
  methods: {
    initData() {
      this.getProdsList();
    },
    getProdsList() {
      get(tranAPI.getTranPartsByCustId, {
        tran_no: this.searchForm.tran_no,
        scon_no: this.searchForm.scon_no,
        cust_id: this.custId,
        status: 2,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.tran_part_id;
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        tran_no: null,
        scon_no: null
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getProdsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanel');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirm', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
