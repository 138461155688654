<template>
  <div>
    <el-table :data="adsoForm.adso_rece_list" border @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary>
      <el-table-column type="selection" width="48" align="center" />
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="客户名称" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.cust_abbr'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].cust_abbr"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无客户名称"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="出运发票号" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.tran_no'">
            <el-input
              :value="helper.transverseTranNo(adsoForm.adso_rece_list[scope.$index].tran_no)"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无出运发票号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="销售合同号" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.scon_no'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].scon_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无销售合同号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户订单号" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.scon_cust_no'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].scon_cust_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无客户订单号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="应收金额" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.adso_subrece'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].adso_subrece"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无应收金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="已收金额" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.adso_reced_total'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].adso_reced_total"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无已收金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="未收金额" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.adso_unreced'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].adso_unreced"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无未收金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="本次冲销金额" width="200">
        <template slot="header">
          <span class="vg_deep_red">本次冲销金额</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'adso_rece_list.' + scope.$index + '.adso_rece_reved'"
            :rules="{ required: true, message: ' ' }"
          >
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].adso_rece_reved"
              @change="adsoNetreceChange(scope.$index)"
              @input="
                adsoForm.adso_rece_list[scope.$index].adso_rece_reved = helper.keepTNum2(
                  adsoForm.adso_rece_list[scope.$index].adso_rece_reved
                )
              "
              @blur="
                adsoForm.adso_rece_list[scope.$index].adso_rece_reved = helper.calcPrice(
                  adsoForm.adso_rece_list[scope.$index].adso_rece_reved,
                  4,
                  100000000
                )
              "
              maxlength="13"
              show-word-limit
              placeholder="暂无本次冲销金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="银行费用" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.adso_rece_char'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].adso_rece_char"
              @change="adsoNetreceChange(scope.$index)"
              @input="
                adsoForm.adso_rece_list[scope.$index].adso_rece_char = helper.keepTNum2(
                  adsoForm.adso_rece_list[scope.$index].adso_rece_char
                )
              "
              @blur="
                adsoForm.adso_rece_list[scope.$index].adso_rece_char = helper.calcPrice(
                  adsoForm.adso_rece_list[scope.$index].adso_rece_char,
                  4,
                  10000
                )
              "
              maxlength="9"
              show-word-limit
              placeholder="暂无银行费用"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="实收金额" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.adso_netrece'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].adso_netrece"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无实收金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="备注" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.adso_rece_remark'">
            <el-input
              v-model="adsoForm.adso_rece_list[scope.$index].adso_rece_remark"
              maxlength="255"
              show-word-limit
              placeholder="暂无备注"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- <el-table-column label="产品描述" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'adso_rece_list.' + scope.$index + '.prod_desc'">
            <el-input v-model="adsoForm.adso_rece_list[scope.$index].prod_desc" disabled maxlength="20" show-word-limit
              placeholder="暂无产品描述">
              <template slot="append">
                  <el-popover
                    placement="bottom"
                    width="600"
                    @show="textEnlargementShow(0,scope.$index)"
                    trigger="click">
                      <textEnlargement ref="textEnlargement" :disabledFlag="true" @textEnlargementChange="textEnlargementChange" :textEnlargementString="textEnlargementString"></textEnlargement>
                    <span slot="reference"><el-link type="info">...</el-link></span>
                  </el-popover>
                </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { custAPI } from '@/api/modules/cust';
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';

export default {
  name: 'AdsoList',
  props: {
    adsoForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      multiSelection: [],
      custOptionList: []
    };
  },
  components: {
    // textEnlargement
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
      this.$emit('handleSelectionChange', this.multiSelection);
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = [];
      for (let i = 0; i < 41; i++) {
        let temp = '';
        if (i === 0) {
          temp = '合计';
        } else {
          temp = '';
        }
        totalList.push(temp);
      }
      columns.forEach((column, index) => {
        let datas = data;
        let totalA = new BigNumber(0);
        let totalB = new BigNumber(0);
        let totalC = new BigNumber(0);
        let totalD = new BigNumber(0);
        let totalE = new BigNumber(0);
        let totalF = new BigNumber(0);
        for (let i in datas) {
          totalA = totalA.plus(datas[i].adso_subrece ? datas[i].adso_subrece : 0);
          totalB = totalB.plus(datas[i].adso_reced_total ? datas[i].adso_reced_total : 0);
          totalC = totalC.plus(datas[i].adso_unreced ? datas[i].adso_unreced : 0);
          totalD = totalD.plus(datas[i].adso_rece_reved ? datas[i].adso_rece_reved : 0);
          totalE = totalE.plus(datas[i].adso_rece_char ? datas[i].adso_rece_char : 0);
          totalF = totalF.plus(datas[i].adso_netrece ? datas[i].adso_netrece : 0);
        }
        totalA = totalA.toNumber();
        totalB = totalB.toNumber();
        totalC = totalC.toNumber();
        totalD = totalD.toNumber();
        totalE = totalE.toNumber();
        totalF = totalF.toNumber();
        totalList[6] = this.helper.haveFour(totalA);
        totalList[7] = this.helper.haveFour(totalB);
        totalList[8] = this.helper.haveFour(totalC);
        totalList[9] = this.helper.haveFour(totalD);
        totalList[10] = this.helper.haveFour(totalE);
        totalList[11] = this.helper.haveFour(totalF);
      });
      return totalList;
    },
    // 计算实收金额
    adsoNetreceChange(val) {
      let totalA = new BigNumber(0);
      totalA = totalA.plus(this.adsoForm.adso_rece_list[val].adso_rece_reved).minus(this.adsoForm.adso_rece_list[val].adso_rece_char);
      totalA = totalA.toNumber();
      this.adsoForm.adso_rece_list[val].adso_netrece = this.helper.haveFour(totalA);
      this.$emit('adsoNetreceChange');
    },

    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.adsoForm.adso_rece_list[val1].prod_desc;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.adsoForm.adso_rece_list[this.textEnlargementNum].prod_desc = val;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
